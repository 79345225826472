<template>
    <div class="action-schedule">
        <template>
            <div class="message">
                <div class="message-top">
                    <img :src="require('@/assets/images/icon-hand.png')" alt="" />
                    <span v-if="changeType === 'user'" class="message-title">변경된 약속 내용을 확인해주세요.</span>
                    <span v-else class="message-title">상대방이 약속 내용 변경을 요청했어요.</span>
                </div>
                <div class="message-content">
                    <div class="grey-box m-t-8">
                        <div class="user-profile">
                            <img :src="photoUrl" alt="" />
                            <div class="profile-info">
                                <div class="age" v-html="age" />
                                <div class="region" v-html="content.region" />
                                <div class="job" v-html="content.job" />
                            </div>
                        </div>
                    </div>
                    <div class="grey-box m-t-8">
                        <div class="title-grey m-l-4">약속 내용</div>
                        <div class="content-grey">
                            <p class="m-b-4 m-l-4">{{ time }}</p>
                            <p class="m-l-4">{{ content.meet_place }}에서 {{ meetType }}예정</p>
                        </div>
                    </div>
                    <div class="green-box m-t-8 m-b-8 p-l-16">
                        <div class="title-green">장소</div>
                        <div v-if="content.restaurant_name === null" class="content-green m-r-4">
                            <div class="m-r-4">미정</div>
                        </div>
                        <div v-else class="content-green m-r-4">
                            <div class="m-r-4">{{ content.restaurant_name }}</div>
                            <div class="place-map">
                                <img
                                    :src="require('@/assets/images/icons/location.png')"
                                    alt=""
                                    widtt="16"
                                    height="16"
                                />
                                <div @click="showMap">지도보기</div>
                            </div>
                        </div>
                    </div>
                    <BottomButton
                        :disabled="isDisabled || isClicked"
                        :nonFixed="true"
                        :label="'변경 내용을 확인했어요'"
                        @click="onClickConfirm"
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ActionScheduleChangeAdmin',
    props: ['message'],
    data: () => ({
        isClicked: false,
    }),
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        photoUrl() {
            return this.content.urls[0] || null
        },
        age() {
            return this.$options.filters.asAgeInActionMessage(this.content.birthday)
        },
        time() {
            const dateInMessage = this.content.date
            const date = this.$moment(dateInMessage).format('YY/MM/DD')
            const day = this.$moment(dateInMessage).format('dddd').toUpperCase()
            const time = this.$moment(dateInMessage).format('A h시 mm분')

            return `${date} ${this.$translate(day)}, ${time}`
        },
        meetType() {
            const fixed = ['coffee', 'meal']

            return fixed.includes(this.content.meet_type_confirmed)
                ? this.$translate(this.content.meet_type_confirmed.toUpperCase())
                : '커피 또는 식사'
        },
        changeType() {
            if (this.content.msg.includes('확인')) {
                return 'user'
            } else {
                return 'target'
            }
        },
        me() {
            return this.$store.getters.me
        },
        isDisabled() {
            return this.content.status >= 1
        },
    },
    methods: {
        async onClickConfirm() {
            const idx = await this.$modal.basic({
                body:
                    '상대방도 변경된 내용을 확인하는대로 약속 확정 메시지를 보내드릴게요.<br />(상대방이 회원님보다 먼저 확인 처리했으면, 회원님이 확인 처리 하자마자 확정 메시지가 발송돼요.)',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const payload = {
                    id: this.content.schedule_id,
                    user_id: this.me.user_id,
                    message_id: this.message.id,
                }
                try {
                    const res = await scheduleService.changeScheduleConfirm(payload)
                    this.$toast.success(res.msg)
                    this.isClicked = true
                } catch (err) {
                    console.error(err)
                }
            }
        },
        showMap() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.content.restaurant_url,
            })
        },
    },
}
</script>
<style scoped lang="scss">
.message-title {
    font-size: 15px;
}
.desc {
    font-size: 14px;
    &.li-dot {
        display: list-item;
        align-items: flex-start;
        margin-left: 16px;
    }
}
.content-green {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    .place-map {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 4px;
        min-width: 69px;
        width: fit-content;
        height: 24px;
        padding: 2px 4px;
        background: #03006e;
        border-radius: 100px;
    }
}
</style>
